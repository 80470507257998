@use '../variables';
@use '../mixins/general';
@use '../mixins/animations';
@use 'sass:math';

$icon-size: 16px;

@mixin make-icon($width, $height) {
  display: inline-block;
  width: $width;
  height: $height;
}

@mixin make-loader-icon() {
  @include make-icon($icon-size, $icon-size);

  position: relative;
  border: 1px solid var(--border-neutral);
  border-radius: 100%;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    width: $icon-size;
    height: $icon-size;
    border-radius: 50%;
    border: 1px solid var(--border-neutral-contrast);
    border-color: var(--border-neutral-contrast) transparent var(--border-neutral-contrast) transparent;
    animation: spin 700ms linear infinite;
  }
}

@mixin build-icon-remove() {
  @include make-icon($icon-size, $icon-size);

  position: relative;

  &:hover {
    &::before,
    &::after {
      border-color: var(--border-danger);
    }
  }

  &::before {
    @include make-icon(1px, calc($icon-size / 2));

    top: calc($icon-size / 2);
    left: calc($icon-size / 2);
    position: absolute;
    content: ' ';
    border: 1px solid var(--border-brand);
    border-width: 0 1px 0 0;
    transform: rotate(45deg);
  }

  &::after {
    @include make-icon(1px, calc($icon-size / 2));

    top: calc($icon-size / 2);
    left: calc($icon-size / 2);
    position: absolute;
    content: ' ';
    border: 1px solid var(--border-brand);
    border-width: 0 1px 0 0;
    transform: rotate(136deg);
  }
}

@mixin build-select-icon($selector) {
  #{$selector} {
    float: right;

    & + #{$selector} {
      &::after {
        right: 9px;
        bottom: 8px;
      }

      &::before {
        bottom: 10px;
      }
    }

    &::after {
      content: ' ';
      transition: all 200ms;
      position: absolute;
      bottom: 15px;
      right: calc((var(--spacer-xs) / 2) + 2);
      border: 1px solid var(--border-brand);
      background: var(--background-primary);
      width: 4px;
      height: 5px;
    }

    &::before {
      content: ' ';
      transition: all 200ms;
      position: absolute;
      bottom: 17px;
      right: calc(var(--spacer-xs) / 2);
      width: 12px;
      height: 1px;
      border-bottom: 1px solid var(--border-brand);
    }
  }
}

@mixin make-icon-dimensions($width, $height) {
  line-height: $height;

  svg {
    width: $width;
    height: $height;
  }
}

@mixin make-icon-sort-control() {
  .sort-control {
    margin-left: var(--spacer-sm);
    position: relative;
    display: inline-block;

    &.asc {
      &::before {
        opacity: 0.1;
      }
    }

    &.desc {
      &::after {
        opacity: 0.1;
      }
    }

    &::before {
      transition: all 200ms;
      content: ' ';
      position: absolute;
      display: inline-block;
      top: -10px;
      width: 6px;
      height: 6px;
      border: 2px solid var(--border-secondary);
      border-bottom-width: 0;
      border-right-width: 0;
      transform: rotate(45deg);
    }

    &::after {
      transition: all 200ms;
      content: ' ';
      position: absolute;
      display: inline-block;
      top: -5px;
      width: 6px;
      height: 6px;
      border: 2px solid var(--border-secondary);
      border-top-width: 0;
      border-left-width: 0;
      transform: rotate(45deg);
    }
  }
}

@mixin make-icon-arrow($dimension, $color) {
  .icon {
    position: relative;

    .icon-arrow {
      height: calc($dimension + ($dimension / 2));
      width: $dimension * 2;
      display: inline-block;

      &.icon-arrow-rotate::after {
        transform: rotate(45deg);
      }

      &.icon-arrow-collapsed::after {
        transform: rotate(135deg);
      }

      &.icon-arrow-expanded::after {
        transform: rotate(225deg);
      }

      &::after {
        content: ' ';
        border: 2px solid $color;
        border-bottom-width: 0;
        border-right-width: 0;
        transform: rotate(225deg);
        transition: transform 200ms;
        width: $dimension;
        height: $dimension;
        position: absolute;
        left: calc($dimension / 2);
      }
    }
  }
}

@mixin make-theme-icons() {
  @include make-icon-arrow(10px, var(--text-secondary));
  @include make-icon-sort-control();

  .icon {
    @include make-icon-dimensions(variables.$icon-size-base, variables.$icon-size-base);

    display: inline-block;

    @each $size, $dimension in variables.$icon-config {
      &.icon-#{$size} {
        @include make-icon-dimensions($dimension, $dimension);
      }
    }

    &.spinner {
      animation: spin 2s infinite linear;
    }
  }

  .icon-flip-horizontal {
    transform: scaleX(-1);
  }

  .icon-flip-vertical {
    transform: scaleY(-1);
  }

  @each $pos, $deg in variables.$icon-rotate-config {
    &.icon-#{$pos} {
      transform: scaleX($deg);
    }
  }
}
