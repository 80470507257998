@use '../variables';

@mixin buildSpacerUtility($prefix, $property, $name, $index) {
  .#{$prefix}-#{$name} {
    #{$property}: $index * variables.$padding-xs !important;
  }

  .#{$prefix}-l-#{$name} {
    #{$property}-left: $index * variables.$padding-xs !important;
  }

  .#{$prefix}-r-#{$name} {
    #{$property}-right: $index * variables.$padding-xs !important;
  }

  .#{$prefix}-t-#{$name} {
    #{$property}-top: $index * variables.$padding-xs !important;
  }

  .#{$prefix}-b-#{$name} {
    #{$property}-bottom: $index * variables.$padding-xs !important;
  }

  .#{$prefix}-x-#{$name} {
    #{$property}-left: $index * variables.$padding-xs !important;
    #{$property}-right: $index * variables.$padding-xs !important;
  }

  .#{$prefix}-y-#{$name} {
    #{$property}-top: $index * variables.$padding-xs !important;
    #{$property}-bottom: $index * variables.$padding-xs !important;
  }
}

@mixin buildSpacer($prefix, $property) {
  $i: 1;

  @each $name in xs sm md lg xl {
    @include buildSpacerUtility($prefix, $property, $name, $i);
    $i: $i + 1;
  }

  .#{$prefix}-none {
    #{$property}: 0 !important;
  }

  .#{$prefix}-l-none {
    #{$property}-left: 0 !important;
  }

  .#{$prefix}-r-none {
    #{$property}-right: 0 !important;
  }

  .#{$prefix}-t-none {
    #{$property}-top: 0 !important;
  }

  .#{$prefix}-b-none {
    #{$property}-bottom: 0 !important;
  }

  .#{$prefix}-x-none {
    #{$property}-left: 0 !important;
    #{$property}-right: 0 !important;
  }

  .#{$prefix}-y-none {
    #{$property}-top: 0 !important;
    #{$property}-bottom: 0 !important;
  }
}

@mixin make-theme-spacer-utilities() {
  @include buildSpacer(p, padding);
  @include buildSpacer(m, margin);
}
