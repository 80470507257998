@mixin setup() {
  .text-label {
    font-weight: var(--font-weight-bold);
  }

  .text-description {
    color: var(--text-neutral-contrast);
    font-size: var(--default-font-size);
    font-weight: var(--default-font-weight);
  }

  .page-title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-medium);
    color: var(--text-secondary);
    line-height: 100%;
  }
}