@mixin make-form-label() {
  label,
  .label {
    display: block;
    position: relative;
    margin: 0 0 var(--spacer-xs) 0;
    color: var(--text-secondary);

    &.form-checkbox-label,
    &.form-radio-label {
      display: inline-block;
    }

    &.disabled {
      color: var(--text-neutral-fill);
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
