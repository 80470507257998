@mixin toast-info() {
  .toast-info {
    width: auto;
    padding: var(--spacer-lg);
    color: var(--text-secondary);
    background-color: var(--background-info-overlay);
    border-left: 4px solid var(--border-info);
  }
}

@mixin toast-success() {
  .toast-success {
    width: auto;
    padding: var(--spacer-lg);
    color: var(--text-secondary);
    background-color: var(--background-success-overlay);
    border-left: 4px solid var(--border-success);
  }
}

@mixin toast-danger() {
  .toast-danger {
    width: auto;
    padding: var(--spacer-lg);
    color: var(--text-secondary);
    background-color: var(--background-danger-overlay);
    border-left: 4px solid var(--border-danger);
  }
}

@mixin toast-warning() {
  .toast-warning {
    width: auto;
    padding: var(--spacer-lg);
    color: var(--text-secondary);
    background-color: var(--background-warning-overlay);
    border-left: 4px solid var(--border-warning);
  }
}

@mixin setup() {
  @include toast-info();
  @include toast-success();
  @include toast-danger();
  @include toast-warning();
}
