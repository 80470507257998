@mixin make-form-group() {
  .form-group {
    position: relative;
    margin-bottom: var(--spacer-md);
    display: block;

    .form-group-inner {
      display: inline-block;
      width: 100%;
      position: relative;
    }

    .form-group-error {
      display: block;
      color: var(--text-danger);
    }

    &.ng-touched.ng-valid {
      @include input-option(var(--border-neutral), var(--text-secondary), var(--background-primary), var(--border-brand), false);
    }

    &.ng-touched.ng-invalid {
      @include input-option(var(--border-danger), var(--text-danger), var(--background-danger-overlay), var(--border-danger), false);
    }

    &.has-prefix {
      @include input-option(var(--border-neutral), var(--text-neutral-contrast), var(--background-primary), var(--border-neutral), true);
    }
  }
}

@mixin input-option($border-color, $text-color, $background-color, $focus-border-color, $has-prefix) {

  input,
  .dropdown-selected {
    border-radius: var(--radius-sm);
    background-color: $background-color;
    color: $text-color;

    @if $has-prefix == true {
      border: none;
      background-color: $background-color;
    }

    &:hover,
    &:focus {
      border-color: $focus-border-color;

      & + .char {
        color: $text-color;
      }
    }
  }

  textarea {
    border-radius: var(--radius-sm);
    background-color: $background-color;
    color: $text-color;
    border: 1px solid $border-color;

    &:hover,
    &:focus {
      border-color: $focus-border-color;

      & + .char {
        color: $text-color;
      }
    }
  }

  .icon {
    fill: $text-color;
  }
}
