@use '../variables';
@use '../mixins/general';
@use '../mixins/arrow';
@use '../mixins/forms';

@mixin make-form-dropdown() {
  .dropdown {
    user-select: none;
    display: block;
    position: relative;
    margin: var(--spacer-xs) 0;

    @include forms.make-disabled('&', '.dropdown-selected');
    @include make-dropdown-size(sm, variables.$form-input-height-sm, variables.$form-input-padding-sm, var(--default-font-size), variables.$form-dropdown-arrow-yDistance-sm, variables.$form-dropdown-arrow-vDistance-sm);
    @include make-dropdown-size(xs, variables.$form-input-height-xs, variables.$form-dropdown-arrow-yPadding, var(--default-font-size), variables.$form-dropdown-arrow-yDistance-xs, variables.$form-dropdown-arrow-vDistance-xs);
    @include make-dropdown-theme(variables.$theme-default, var(--text-secondary), var(--text-neutral-contrast), var(--background-primary), var(--background-primary), var(--background-neutral-overlay), var(--border-neutral-fill), var(--text-neutral-contrast));

    &.dropdown-expended {
      .dropdown-body-hook .dropdown-body {
        display: block;
        border-radius: var(--radius-sm);
        box-shadow: var(--shadow-sm);
      }

      .dropdown-selected {
        @include arrow.make-arrow-top(after, variables.$form-dropdown-arrow-yDistance, variables.$form-dropdown-arrow-vDistance);
      }
    }

    .dropdown-item {
      @include general.control();

      padding: variables.$form-input-padding;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .dropdown-selected {
      cursor: pointer;
      min-height: variables.$form-input-height;
      padding-right: 32px;

      @include forms.input-control();
      @include arrow.make-arrow-bottom(after, variables.$form-dropdown-arrow-yDistance, variables.$form-dropdown-arrow-vDistance);
    }

    .dropdown-body-hook {
      position: relative;
      display: block;

      .dropdown-body {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: var(--default-z-index);
        box-shadow: var(--shadow-sm);
        max-height: variables.$form-dropdown-content-max-height;
        overflow-y: auto;

        .dropdown-filter {
          width: 100%;
          padding: variables.$form-input-padding;

          .input {
            background-color: transparent;
            border-bottom: 1px solid var(--border-neutral);
          }

          .search-icon {
            color: var(--text-neutral-contrast);
          }

          &:hover .search-icon {
            color: var(--text-brand);
          }

          .dropdown-filter-icon {
            padding: variables.$form-input-padding;
          }
        }
      }
    }
  }
}

@mixin make-dropdown-size($size, $height, $paddingY, $fontSize, $arrowXDistance, $arrowYDistance) {
  &.dropdown-#{$size} {
    &.dropdown-expended .dropdown-selected {
      @include arrow.make-arrow-top(after, $arrowXDistance, $arrowYDistance);
    }

    .dropdown-selected {
      min-height: $height;
      max-height: $height;

      @include arrow.make-arrow-bottom(after, $arrowXDistance, $arrowYDistance);

      .dropdown-item {
        font-size: $fontSize;
        padding-top: $paddingY;
        padding-bottom: $paddingY;
      }
    }
  }
}

@mixin make-dropdown-theme($theme, $textColor, $arrowColor, $bgColor, $bodyItemEvenBgColor, $bodyItemOddBgColor, $itemBorderColor, $placeholderColor) {
  &.dropdown-#{$theme} {
    color: $textColor;

    &.dropdown-expended .dropdown-selected {
      border-color: var(--border-brand);

      &:hover {
        border-color: var(--border-brand);
      }

      &::after {
        border-color: $arrowColor;
      }
    }

    .dropdown-selected {
      border-radius: var(--radius-sm);
      border-color: $itemBorderColor;

      .placeholder {
        color: $placeholderColor;
      }

      &:hover {
        border-color: var(--border-brand);
      }
    }

    .dropdown-body {
      background-color: $bgColor;

      .dropdown-items {
        .dropdown-item {

          &:nth-child(even) {
            background-color: $bodyItemEvenBgColor;
          }

          &:nth-child(odd) {
            background-color: $bodyItemOddBgColor;
          }

          &.selected {
            color: var(--text-brand);
            font-weight: var(--font-weight-medium);
          }

          &:hover {
            color: var(--text-brand);
          }
        }
      }
    }
  }
}
