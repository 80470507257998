@use '../variables';

$menuMaxWidth: 260px;

@mixin make-theme-card-layout() {
  .card-layout {
    display: flex;
    flex-direction: column;

    .card-layout-title {
      display: flex;
      flex-direction: row;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      position: relative;
      border-bottom: none;
      padding: 0;

      @include make-card-search();

      .header-options {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;

        .header-tabs,
        .header-search {
          display: flex;
          flex-direction: row;
          margin-bottom: 0;
          justify-content: center;
          align-items: center;
        }
      }

      .card-layout-header {
        cursor: pointer;
        position: relative;
        user-select: none;
        height: 100%;
        display: flex;
        align-items: center;
        padding-top: var(--spacer-sm);
        padding-bottom: var(--spacer-sm);

        &:last-child {
          border-right: none;
          padding-right: 0;
        }

        &.header-item-label-active {
          font-weight: var(--font-weight-medium);
          color: var(--text-brand);
          background-color: var(--background-neutral-contrast);
          border-bottom: 5px solid var(--border-brand);
        }

        &.header-item-label-inactive {
          font-weight: var(--font-weight-medium);
          color: var(--text-primary);
        }

        &.disabled {
          color: var(--text-neutral-fill);
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }

    .card-layout-body {
      display: flex;
      position: relative;
      width: 100%;
      flex-grow: 1;
      min-height: 0;

      .card-loading {
        display: flex;
        height: auto;
        max-width: 100%;
      }

      aside {
        &.card-layout-aside-menu {
          min-width: $menuMaxWidth - 50px;
          max-width: $menuMaxWidth;
        }

        .card-layout-menu-item-header {
          background-color: var(--background-neutral-overlay);
        }
      }

      .menu-container-overflow-y {
        overflow-y: auto;
      }

      .card-menu-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: fit-content;
      }

      .card-inner-body {
        width: 100%;
      }

      .card-layout-menu {
        .menu-item-label {
          cursor: pointer;
          padding: var(--spacer-md) var(--spacer-md) var(--spacer-md) calc(var(--spacer-xl) * 2);
          position: relative;
          user-select: none;
          line-height: var(--default-line-height);
          overflow: hidden;
          text-overflow: ellipsis;

          .icon {
            position: absolute;
            left: var(--spacer-xl);
            top: var(--spacer-xl);
            transform: translateY(-50%);
            fill: var(--text-neutral-contrast);
          }

          .icon-up {
            transform: rotate(-90deg);
          }

          .icon-down {
            transform: rotate(90deg);
          }

          &.menu-item-label-active {
            font-weight: var(--font-weight-medium);
            background-color: var(--background-neutral-overlay);
          }

          &.disabled {
            background-color: var(--background-primary);
            color: var(--text-neutral-fill);
          }
        }

        .menu-item-children {
          .menu-item-label {
            padding-left: calc(var(--spacer-xl) * 2.7);

            span {
              font-size: var(--default-font-size);
            }
          }
        }
      }
    }
  }
}

@mixin make-card-search() {
  input {
    background: transparent;
    margin: 0;
    height: 50px;
    width: 200px;
    color: var(--text-primary);
    border: 0 none;
    border-left: 1px solid var(--border-neutral);

    &::placeholder {
      color: var(--text-neutral-fill);
    }

    &:-ms-input-placeholder {
      color: var(--text-neutral-fill);
    }

    &::-ms-input-placeholder {
      color: var(--text-neutral-fill);
    }
  }

  .suffix {
    color: var(--text-primary);
    fill: var(--text-neutral-fill);
  }

  .search {
    position: absolute;
    right: 0;
    top: 0;
  }
}
