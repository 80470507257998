@use '../mixins/forms';

@mixin make-input() {
  input {
    padding: var(--spacer-md);
    margin: var(--spacer-xs) 0;
    width: 100%;
    display: block;
    user-select: auto;

    @include reset-webkit-state();
    @include make-placeholder();
    @include forms.input-control();
    @include forms.make_disabled();
    @include forms.make-hover-state();
    @include forms.make-focus-state();
    @include forms.make-input-sizes();
  }
}

@mixin reset-webkit-state() {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

@mixin make-placeholder() {
  &::placeholder,
  &:-ms-input-placeholder {
    color: var(--text-neutral-contrast);
  }
}
