@use '../variables';

@mixin make-theme-container() {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  align-content: stretch;
  min-height: 100vh;

  hr {
    border: none;
    height: 1px;
    background-color: var(--border-neutral);
  }
}

@mixin make-theme-main-content {
  .main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    margin-bottom: var(--spacer-xl);
    margin-top: var(--spacer-xl);
    height: variables.$shell-content-height;
    overflow-x: hidden;

    .inner-content {
      display: block;
      padding-left: calc(var(--spacer-lg) * 2);
      padding-right: calc(var(--spacer-lg) * 2);
      min-width: 100%;
      max-width: 100%;
    }

    .inner-content-centered {
      align-self: center;

      @media (min-width: variables.$grid-xxl-min-width) {
        min-width: calc(100vw - #{variables.$left-menu-container-width});
        max-width: calc(100vw - #{variables.$left-menu-container-width});
      }
    }
  }
}
