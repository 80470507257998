@use '../variables';

@mixin make-theme-queries() {
  @media screen and (max-width: variables.$grid-xl-max-width) {
    .left-menu-container {
      margin-left: -#{variables.$left-menu-container-width};
      transition: all 200ms;

      .expand-container {
        display: block;
        position: fixed;
        top: 15px;
        left: 15px;
      }

      &.expanded {
        margin-left: 0;
        padding: var(--spacer-md);
      }
    }
  }

  @media screen and (min-width: variables.$grid-xxl-min-width) {
    .left-menu-container {
      .expand-container {
        display: none;
      }
    }
  }
}
