@mixin make-theme-list() {
  .list-unstyled {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .list-accent {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      padding-left: var(--spacer-sm);
      position: relative;

      &::before {
        color: var(--text-brand);
        content: '\2022';
        font-size: 30px;
        position: absolute;
        top: -16px;
        left: 0;
      }
    }
  }
}
