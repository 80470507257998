$arrow: 8px;

@mixin make-arrow-bottom($location, $xDistance: 0, $yDistance: 0) {
  @include make-arrow-container($location);

  &:#{$location} {
    @include make-arrow($location, var(--text-neutral-contrast),  225deg, $xDistance);

    top: $yDistance;
  }
}

@mixin make-arrow-top($location, $xDistance: 0, $yDistance: 0) {
  @include make-arrow-container($location);

  &:#{$location} {
    @include make-arrow($location, var(--text-neutral-contrast), 45deg, $xDistance);

    top: calc($yDistance + var(--spacer-xs));
  }
}

@mixin make-arrow($location, $color, $rotate, $xDistance) {
  content: ' ';
  border: 2px solid $color;
  border-bottom-width: 0;
  border-right-width: 0;
  transform: rotate($rotate);
  transition: all 200ms;
  width: $arrow;
  height: $arrow;
  position: absolute;

  @if $location == before {
    left: $xDistance;
  }

  @if $location == after {
    right: $xDistance;
  }
}

@mixin make-arrow-container($location) {
  position: relative;
}
