@mixin setup() {
  .popover {
    position: relative;
    display: inline-block;

    .popover-content {
      background-color: var(--background-primary);
      z-index: calc(var(--default-z-index) + 2);
      display: none;
      position: absolute;
      overflow: auto;
      border-radius: var(--radius-sm);
      box-shadow: var(--shadow-sm);
      border: 1px solid var(--border-neutral);

      .popover-header {
        padding: var(--spacer-xs) var(--spacer-sm);
        border-bottom: 1px solid var(--border-neutral);
        font-size: var(--default-font-size);
      }

      .popover-body {
        padding: var(--spacer-sm) var(--spacer-md);
      }

      .popover-footer {
        padding: var(--spacer-sm) var(--spacer-md);
      }

      &.show {
        display: inline-block;
      }
    }
  }
}
