@mixin make-theme-infinite-scroll() {
  rqc-infinite-scroll {
    display: block;

    .loading-card {
      min-height: 50px;
    }
  }

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacer-xs);

    &.first-load {
      height: 99%;
    }
  }

  .no-data-message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacer-xs);
    height: 99%;
  }
}
