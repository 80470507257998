@use '../variables';
@use 'mixins/grid';
@use 'mixins/grid-framework';

@if variables.$enable-grid-classes {
  .container {
    @include grid.make-container();
    @include grid.make-container-max-widths();
  }
}

@if variables.$enable-grid-classes {
  .container-fluid {
    @include grid.make-container();
  }
}

@if variables.$enable-grid-classes {
  .row {
    @include grid.make-row();
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*='col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@if variables.$enable-grid-classes {
  @include grid-framework.make-grid-columns();
}
