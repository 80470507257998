@mixin setup() {
  ::-webkit-scrollbar {
    height: var(--spacer-lg);
    width: var(--spacer-xs);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--background-neutral-overlay);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--background-neutral-contrast);
    border-radius: var(--radius-sm);
    border: transparent;

    &:hover {
      background-color: var(--background-neutral-contrast);
    }
  }
}