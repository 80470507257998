@use '../variables';
@use '../mixins/general';

@mixin make-theme-cards() {
  .card {
    border: 1px solid var(--border-neutral);
    border-radius: var(--radius-sm);
    box-shadow: var(--shadow-sm);
    background-color: var(--background-primary);
    display: flex;
    flex-direction: column;

    @include make-card-reset();
    @include make-card-xs();
    @include make-card-theme(variables.$theme-grey, var(--text-primary), var(--background-neutral-contrast), 0);

    .card-title {
      padding: var(--spacer-md);
      background-color: var(--background-neutral-contrast);
      color: var(--text-primary);
      border-bottom: var(--border-neutral);
      border-radius: var(--radius-sm) var(--radius-sm) 0 0;
      font-weight: var(--font-weight-medium);
      flex-grow: 0;
      display: flex;
      font-size: var(--font-size-sm);
    }

    .card-body {
      flex-grow: 1;
      padding: var(--spacer-lg);

      .separator {
        border-top: var(--border-neutral);
        height: 1px;
        margin: var(--spacer-lg) calc(-1 * var(--spacer-lg));
      }
    }
  }
}

@mixin make-card-reset() {
  &.card-reset {
    box-shadow: none;
    border: none;

    .card-body {
      padding: 0;
    }
  }
}

@mixin make-card-xs() {
  &.card-xs {
    .card-title {
      padding: var(--spacer-xs) var(--spacer-md);
      font-size: var(--default-font-size);
    }

    .card-body {
      padding: var(--spacer-xs) var(--spacer-md);
    }
  }
}

@mixin make-card-theme($themeName, $headerColor,  $headerBackground, $borderRadius) {
  &.card-#{$themeName} {
    border-radius: $borderRadius;

    .card-title {
      color: $headerColor;
      background-color: $headerBackground;
      border-radius: $borderRadius;
    }
  }
}
