@use 'sass:math';

$content-max-height: 300px;

@mixin make-dropdown-menu() {
  rqc-dropdown-menu {
    display: block;
    outline: none;
    max-width: 100%;
    position: relative;

    .dropdown-menu {
      position: relative;

      &:hover {
        > rqc-dropdown-items {
          left: 0;
          height: auto;
          overflow: visible;
          display: block;
        }

        > .placeholder {
          color: var(--text-brand);

          .arrowup {
            transform: rotate(45deg);
          }
        }
      }
    }

    .placeholder {
      padding: var(--spacer-md);
      cursor: pointer;
      font-size: var(--font-size-sm);
      position: relative;
      text-align: left;
      white-space: nowrap;
      user-select: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .arrowup {
        width: calc(var(--font-size-sm) / 2);
        height: calc(var(--font-size-sm) / 2);
        border: 2px solid var(--text-neutral-contrast);
        border-bottom-width: 0;
        border-right-width: 0;
        transform: rotate(225deg);
        transition: all 100ms;
        margin-left: var(--spacer-sm);
      }
    }

    .item {
      user-select: none;
      outline: none;
      display: block;
      position: relative;
      cursor: pointer;
      text-align: left;
      white-space: nowrap;
      padding: var(--spacer-md);
      border-bottom: 1px solid var(--border-neutral);

      &:hover {
        color: var(--text-brand);
      }

      &.selected {
        color: var(--text-brand);
        font-weight: var(--font-weight-medium);
      }
    }

    rqc-dropdown-items {
      display: none;
      position: absolute;
      top: 100%;
      overflow: hidden;
      width: auto;
      min-width: 100%;
      z-index: var(--default-z-index);
      box-shadow: var(--shadow-md);
      max-height: $content-max-height;
      border: 1px solid var(--border-neutral-fill);
      border-bottom: none;

      .item {
        &:nth-child(even) {
          background-color: var(--background-primary);
        }

        &:nth-child(odd) {
          background-color: var(--background-neutral-overlay);
        }
      }
    }

    .expanded-item {
      position: relative;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .arrowright {
        width: calc(var(--input-radio-size) / 2);
        height: calc(var(--input-radio-size) / 2);
        border: 2px solid var(--text-neutral-contrast);
        border-top-width: 0;
        border-right-width: 0;
        transform: rotate(225deg);
        transition: all 200ms;
        margin-left: var(--spacer-sm);
      }

      &:hover {
        > .item {
          color: var(--text-brand);
        }

        > rqc-dropdown-items {
          display: block;
          top: 0;
          left: 100%;
          height: auto;
          overflow: visible;
        }
      }
    }
  }
}
