@use '../variables';

@mixin buildBorder($suffix) {
  .b-#{$suffix} {
    border-width: 0;
    border-#{$suffix}-width: 1px;
  }

  .b-#{$suffix}-none {
    border-#{$suffix}: none !important;
  }
}

@mixin make-theme-borders() {
  .b {
    border: 1px solid var(--border-neutral);

    @each $className, $color in variables.$border-config {
      &.b-color-#{$className} {
        border-color: $color;
      }
    }

    &.b-none {
      border: 0 none !important;
    }
  }

  @include buildBorder(top);
  @include buildBorder(bottom);
  @include buildBorder(left);
  @include buildBorder(right);
}
