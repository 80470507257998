@use '../variables';

@mixin make-form-button-group() {
  .button-group {
    display: flex;
    flex-direction: column;
    margin: var(--spacer-sm) 0;

    button {
      &:not(.selected) {
        color: var(--text-neutral-contrast);
        border-color: var(--border-neutral);
        background-color: var(--background-primary);
      }

      @each $className, $color in variables.$button-config {
        &.#{$className}.outline {
          &:hover {
            background-color: var(--background-neutral-overlay);
            color: $color;
            border-color: var(--border-neutral);
          }

          &.disabled:not(.selected):hover {
            color: var(--text-neutral-fill);
            border-color: var(--border-neutral);
          }
        }

        &.selected.disabled.#{$className} {
          color: rgba($color, 0.5);
          border-color: var(--border-neutral);
        }

        &.selected,
        &:hover {
          box-shadow: var(--shadow-sm);
        }
      }
    }
  }
}
