@use '../mixins/forms';

$form-textarea-height: 100px;

@mixin make-form-textarea() {
  textarea {
    @include forms.input-control();
    @include forms.make_disabled();

    padding: var(--spacer-md);
    margin: var(--spacer-sm) 0;
    width: 100%;
    display: inline-block;
    user-select: auto;
    height: $form-textarea-height;
    border-radius: var(--radius-sm);

    &:hover {
      border-color: var(--border-brand);
    }
    &:focus,
    &:active {
      border-color: var(--border-brand);
    }
    &::placeholder {
      color: var(--text-neutral-contrast);
    }
    &:-ms-input-placeholder {
      color: var(--text-neutral-contrast);
    }
    &::-ms-input-placeholder {
      color: var(--text-neutral-contrast);
    }
  }
}
