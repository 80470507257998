@mixin btn($bg-color, $text-color, $hover-bg-color, $border-color) {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: 1px solid $border-color;
  border-radius: var(--radius-sm);
  gap: var(--spacer-sm);
  font-size: var(--default-font-size);
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  width: auto;
  min-width: 100px;
  box-shadow: var(--shadow-xs);
  background-color: $bg-color;
  color: $text-color;
  transition: all 0.3s ease-in-out;

  svg {
    color: $text-color;
  }

  &:hover,
  &:active {
    background-color: $hover-bg-color;
    border-color: $border-color;
    color: $text-color;

    svg {
      color: $text-color;
    }
  }

  &.disabled,
  &:disabled {
    background-color: var(--background-neutral-overlay);
    color: var(--text-neutral-fill);
    border-color: var(--border-neutral);
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none;

    svg {
      color: var(--text-neutral-fill);
    }
  }
}

@mixin create-btn($class, $bg-color, $text-color, $hover-bg-color, $border-color) {
  .#{$class} {
    @include btn($bg-color, $text-color, $hover-bg-color, $border-color);

    padding: var(--spacer-sm) var(--spacer-md);
  }
}

@mixin create-btn-sm($class, $bg-color, $text-color, $hover-bg-color, $border-color) {
  .#{$class} {
    @include btn($bg-color, $text-color, $hover-bg-color, $border-color);

    padding: var(--spacer-xs) var(--spacer-sm);
  }
}

@mixin create-btn-icon($class, $bg-color, $text-color, $hover-bg-color, $border-color) {
  .#{$class} {
    @include btn($bg-color, $text-color, $hover-bg-color, $border-color);

    min-width: 0;
    padding: var(--spacer-sm);
  }
}

@mixin create-btn-icon-sm($class, $bg-color, $text-color, $hover-bg-color, $border-color) {
  .#{$class} {
    @include btn($bg-color, $text-color, $hover-bg-color, $border-color);

    min-width: 0;
    padding: var(--spacer-xs);
  }
}

@mixin setup() {
  @include create-btn('btn-primary', var(--background-brand), var(--text-primary), var(--background-brand-contrast), transparent);
  @include create-btn('btn-secondary', var(--background-primary), var(--text-secondary), var(--background-neutral-overlay), var(--border-neutral-fill));
  @include create-btn('btn-danger', var(--background-danger), var(--text-primary), var(--background-danger-contrast), transparent);
  @include create-btn-sm('btn-primary-sm', var(--background-brand), var(--text-secondary), var(--background-brand-contrast), transparent);
  @include create-btn-sm('btn-secondary-sm', var(--background-primary), var(--text-secondary), var(--background-neutral-overlay), var(--border-neutral-fill));
  @include create-btn-sm('btn-danger-sm', var(--background-danger), var(--text-primary), var(--background-danger-contrast), transparent);
  @include create-btn-icon('btn-icon', var(--background-primary), var(--text-secondary), var(--background-neutral-overlay), var(--border-neutral-fill));
  @include create-btn-icon-sm('btn-icon-sm', var(--background-primary), var(--text-secondary), var(--background-neutral-overlay), var(--border-neutral-fill));
}