@use '../../variables';
@use 'breakpoints';
@use 'sass:math';

$grid-gutter-width: 30px;

@mixin make-container() {
  width: 100%;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: variables.$container-max-widths, $breakpoints: variables.$grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include breakpoints.media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * ($grid-gutter-width / 2));
  margin-left: calc(-1 * ($grid-gutter-width / 2));
}

@mixin make-col-ready() {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
}

@mixin make-col($size, $columns: var(--spacer-xl)) {
  flex: 0 0 percentage(math.div($size, $columns));
  max-width: percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: var(--spacer-xl)) {
  $num: math.div($size, $columns);

  margin-left: if($num == 0, 0, percentage($num));
}
