@use '../variables';

@mixin buildColorUtility($name, $color) {
  .#{$name}-color:not(.disabled) {
    color: $color !important;
  }

  .#{$name}-bg-color {
    background-color: $color !important;
  }

  .#{$name}-fill {
    fill: $color !important;
  }
}

@mixin make-theme-color-utilities() {
  @each $key, $color in variables.$color-config {
    @include buildColorUtility($key, $color);
  }
}
