/* stylelint-disable */

@use 'sass:math';

/** generic */
$color-white: #fff; // Replaced
$color-black: #1c1b20; // Replaced

/** Primary */
$color-primary: #596367;

$color-primary-l-10: #737d81;
$color-primary-l-20: #8c969a;
$color-primary-l-30: #a6b0b4;
$color-primary-l-40: #bfc9cd;
$color-primary-l-50: #e8e8e8;
$color-primary-l-60: #f8f9fa;

$color-primary-d-10: #4c565a;
$color-primary-d-20: #404a4e;
$color-primary-d-30: #333d41;

/** Secondary */
$color-accent: #ff7a4f;

$color-accent-l-10: #ff9621;
$color-accent-l-20: #ffaf3a;
$color-accent-l-30: #ffc954;
$color-accent-l-40: #ffe26d;
$color-accent-l-50: #fff6d3;

$color-accent-d-10: #eb6f00;
$color-accent-d-20: #df6300;
$color-accent-d-30: #d25600;
$color-accent-d-40: #c54900;

/** Color Danger */
$color-danger: #fb440e;
$color-danger-l-30: #ffc38d;
$color-danger-l-40: #d4c0ad;
$color-danger-l-50: #ffd387;

/** Color success */
$color-success: #00bfa5;

$color-blue: #079efa;

$color-config: (
  'black': $color-black,
  'white': $color-white,
  'primary': $color-primary,
  'primary-l-10': $color-primary-l-10,
  'primary-l-20': $color-primary-l-20,
  'primary-l-30': $color-primary-l-30,
  'primary-l-40': $color-primary-l-40,
  'primary-l-50': $color-primary-l-50,
  'primary-d-10': $color-primary-d-10,
  'primary-d-20': $color-primary-d-20,
  'primary-d-30': $color-primary-d-30,
  'accent': $color-accent,
  'accent-l-10': $color-accent,
  'accent-l-20': $color-accent-l-20,
  'accent-l-30': $color-accent-l-30,
  'accent-l-40': $color-accent-l-40,
  'accent-l-50': $color-accent-l-50,
  'accent-d-10': $color-accent-d-10,
  'accent-d-20': $color-accent-d-20,
  'accent-d-30': $color-accent-d-30,
  'accent-d-40': $color-accent-d-40,
  'danger': $color-danger,
  'danger-l-30': $color-danger-l-30,
  'danger-l-40': $color-danger-l-40,
  'danger-l-50': $color-danger-l-50,
  'success': $color-success,
  'blue': $color-blue,
  'disabled': $color-primary-l-40,
);

/** Border **/
$border-config: (
  'accent': $color-accent,
  'accent-d-30': $color-accent-d-30,
  'primary': $color-primary,
  'primary-l-40': $color-primary-l-40,
  'danger': $color-danger,
  'danger-l-40': $color-danger-l-40,
  'danger-l-50': $color-danger-l-50,
  'success': $color-success,
  'blue': $color-blue,
  'white': $color-white,
);

/** theme */
$theme-default: 'default';
$theme-grey: 'grey';
$theme-primary: 'primary';
$theme-accent: 'accent';
$theme-success: 'success';
$theme-danger: 'danger';
$theme-blue: 'blue';
$theme-disabled: 'disabled';

$theme-config: (
  $theme-default: $color-primary,
    $theme-grey: $color-primary-l-40,
      $theme-primary: $color-primary,
        $theme-accent: $color-accent,
          $theme-success: $color-success,
            $theme-danger: $color-danger,
              $theme-blue: $color-blue,
                $theme-disabled: $color-primary-l-50,
                );

              /** Fonts */
// $font-family-base: 'Roboto', sans-serif;
// $font-size-xs: 12px !default;
// $font-size-base: 14px;
// $font-size-sm: 16px;
// $font-size-md: 18px;
// $font-size-lg: 20px !default;
// $font-size-xl: 30px !default;
// $font-size-xxl: 50px !default;

// $font-weight-lighter: lighter !default;
// $font-weight-light: 300 !default;
// $font-weight-normal: 400 !default;
// $font-weight-bold: 600 !default;
// $font-weight-bolder: bolder !default;
// $font-weight-base: $font-weight-normal !default;

// $line-height-base: 1.5 !default;

// $h1-font-size: $font-size-lg;
// $h2-font-size: $font-size-md;
// $h3-font-size: $font-size-sm;
// $h4-font-size: $font-size-base;
// $h5-font-size: $font-size-xs;
// $h6-font-size: $font-size-xs;

$spacer: 1rem !default;
$headings-margin-bottom: (
                math.div($spacer, 2)) !default;
// $headings-font-family: inherit !default;
// $headings-font-weight: $font-weight-bold;
// $headings-line-height: 1.2 !default;

              /** padding **/
$padding-xs: 5px;
$padding-sm: $padding-xs * 2;
$padding-md: $padding-xs * 3;
$padding-lg: $padding-xs * 4;
$padding-xl: $padding-xs * 5;

              /** margins **/
$margin-xs: 5px;
$margin-sm: $margin-xs * 2;
$margin-md: $margin-xs * 3;
$margin-lg: $margin-xs * 4;
$margin-xl: $margin-xs * 5;

              /** Grid **/
$enable-grid-classes: true;

// $grid-columns: 12 !default;
// $grid-gutter-width: 30px !default;

$container-max-widths: (
                sm: 540px,
                md: 720px,
                lg: 960px,
                xl: 1140px) !default;

$grid-xs-max-width: 575px;

$grid-sm-min-width: $grid-xs-max-width + 1;
$grid-sm-max-width: 767px;

$grid-md-min-width: $grid-sm-max-width + 1;
$grid-md-max-width: 991px;

$grid-lg-min-width: $grid-md-max-width + 1;
$grid-lg-max-width: 1199px;

$grid-xl-min-width: $grid-lg-max-width + 1;
$grid-xl-max-width: 1399px;

$grid-xxl-min-width: $grid-xl-max-width + 1;

$grid-breakpoints: (
                xs: 0,
                sm: $grid-sm-min-width,
                md: $grid-md-min-width,
                lg: $grid-lg-min-width,
                xl: $grid-xl-min-width,
                xxl: $grid-xxl-min-width) !default;

              /** Interaction elements generic definitions **/
// $control-disabled-color: $color-primary-l-50;
// $control-margin: 5px;

              /** Buttons **/
// $button-padding: 9px;
// $button-padding-xs: $padding-xs;
// $button-padding-sm: $padding-sm;
// $button-padding-md: $padding-md;
// $button-font-weight: 600;
// $button-min-width: 100 - $button-padding * 2;
$button-config: (
                'accent': $color-accent,
                'accent-d-30': $color-accent-d-30,
                'success': $color-success,
                'danger': $color-danger,
                'danger-l-40': $color-danger-l-40,
                'danger-l-50': $color-danger-l-50,
                'blue': $color-blue,
                'black': $color-black,
                'primary': $color-primary,
                'primary-l-40': $color-primary-l-40,
              );

            /** Forms */
// $form-control-margin: $margin-md;

$form-input-margin: $margin-xs;
// $form-input-margin-v: $margin-xs;
// $form-input-margin-h: 0;

$form-control-width: 200px;

$form-input-height: 45px;
$form-input-padding: 11px;
$form-input-height-total: $form-input-height + ($form-input-margin * 2);

$form-input-padding-sm: 8px;
$form-input-height-sm: 38px;
$form-input-height-total-sm: $form-input-height-sm + ($form-input-margin * 2);

$form-input-height-xs: 30px;
$form-input-padding-xs: $padding-xs;
$form-input-height-total-xs: $form-input-height-xs + ($form-input-margin * 2);

$form-input-size-config: (
              'xs': (height: $form-input-height-xs, padding: $form-input-padding-xs),
              'sm': (height: $form-input-height-sm, padding: $form-input-padding-sm),
              'md': (height: $form-input-height, padding: $form-input-padding),
            );

$form-input-line-height: 22px;
// $form-input-prefix-font-size: $font-size-base;
$form-input-icon-margin: 10px;
$form-input-prefix-suffix-width: 25px;
$form-input-prefix-suffix-width-md: 40px;

// $form-input-border-width: 1px;
// $form-input-border-style: solid;

// $form-input-radio-size-sm: 8px;
$form-input-radio-size: 16px;
$form-input-radio-border-width: 1px;
$form-input-height: 45px;
$form-numeric-button-width: 25px;
$form-numeric-arrow-size: 5px;
// $form-button-group-padding: $form-input-padding $padding-lg;

// $form-textarea-height: 100px;

$form-control-xs-width: 180px;
$form-control-sm-width: 260px;
$form-control-md-width: 380px;
$form-control-lg-width: 600px;
$form-control-xl-width: 100%;

$form-control-config: (
            'xs': $form-control-xs-width,
            'sm': $form-control-sm-width,
            'md': $form-control-md-width,
            'lg': $form-control-lg-width,
            'xl': $form-control-xl-width
          );

        /** Arrow **/
// $arrow-size: math.div($form-input-radio-size, 2);
// $arrow-padding: $padding-lg;

        /**** Dropdown ***/
$form-dropdown-arrow-vDistance: 15px;
$form-dropdown-arrow-yDistance: 16px;

$form-dropdown-arrow-vDistance-sm: 12px;
$form-dropdown-arrow-yDistance-sm: $form-dropdown-arrow-yDistance;

$form-dropdown-arrow-yPadding: 3.5px;
$form-dropdown-arrow-vDistance-xs: 9px;
$form-dropdown-arrow-yDistance-xs: 12px;

$form-dropdown-content-max-height: 300px;
$form-dropdown-zIndex: 600;

        /** Tooltip */
// $tooltip-padding-h: $padding-sm;
// $tooltip-padding-w: $padding-md;
// $tooltip-font-weight: $font-weight-bold;

        /** Charts **/
// $chart-text-color: $color-primary-l-20;
// $chart-font-size: $font-size-xs;
// $chart-font-weight: $font-weight-normal;
// $chart-font-weight-bold: $font-weight-bold;
// $chart-title-font-size: $font-size-base;
// $chart-legend-disabled-color: $color-primary-l-50;
// $chart-area-opacity: 0.3;
// $chart-legend-bg-width: 22px;
// $chart-legend-bg-height: 7px;

        /** Table */
// $table-header-font-weight: $font-weight-bold;

        /** z-indexes **/
// $zIndex-xs: 1000;
// $zIndex-sm: 1100;
// $zIndex-md: 1200;
// $zIndex-lg: 1300;
// $zIndex-xl: 1400;

        /** Icons **/
$icon-size-base: 16px;
$icon-size-xs: 8px;
$icon-size-sm: 12px;
$icon-size-md: 24px;
$icon-size-lg: $icon-size-base * 2 !default;
$icon-size-xl: $icon-size-base * 3 !default;
$icon-config: (
          'xs': $icon-size-xs,
          'sm': $icon-size-sm,
          'md': $icon-size-md,
          'lg': $icon-size-lg,
          'xl': $icon-size-xl,
        );
$icon-rotate-config: (
        'top': 1,
        'right': 45deg,
        'bottom': 135deg,
        'left': 180deg,
      );

    /** Badges **/
// $badge-v-padding: 4px;
// $badge-h-padding: $padding-sm;
// $badge-border-radius: 4px;

    /** Modals **/
// $modal-sm-width: 400px;
// $modal-md-width: 600px;
// $modal-lg-width: 800px;
// $modal-xl-width: 1100px;
// $modal-fluid-width: 95%;
// $modal-padding-horizontal: $padding-xl;
// $modal-padding-vertical-xl: $padding-xl;
// $modal-padding-vertical-md: $padding-md;

// $modal-title-line-height: 0.8;

    /** Cards **/
// $card-padding: $padding-lg;
// $card-margin: 40px;
// $card-title-padding: $padding-md;
// $card-font-weight: 600;
// $card-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.03);

    /** Comparison **/
// $comparison-row-height: 50px;
// $comparison-row-padding: $card-title-padding;
// $comparison-gutter-width: math.div($grid-gutter-width, 4);

    /** Pager **/
// $pager-margin-right: 5px;
$pager-button-line-height: $form-input-height-xs - 2px;
$pager-padding: 3px 0;
$pager-dropdown-margin: 0 0 0 $margin-lg;
$pager-dropdown-height: 30px;
$pager-dropdown-item-padding: 3px $padding-xl 3px $padding-sm;
$pager-dropdown-arrow-down-bottom: 13px;
$pager-dropdown-arrow-up-bottom: 10px;
$pager-dropdown-arrow-right: 10px;

    /** Popover **/
// $popover-content-max-width: 200px;
// $popover-content-max-height: 400px;

    /** Messages **/
// $message-border-width: 1px;
// $message-border-style: solid;
// $message-padding: $padding-md;
// $message-v-margin: $margin-lg;

    /** Upload **/
// $upload-control-width: 250px;
// $upload-button-padding: 18px 40px;

    /** Switch **/
// $switch-width: 56px;
// $switch-height: 30px;

    /** Switch **/
// $wizzard-item-width: 38px;
// $wizzard-item-height: 38px;
// $wizzard-line-margin: $margin-sm;

    /** Filter **/
$filter-item-height: 30px;
$filter-item-width: 150px;
$filter-search-padding: math.div($padding-xs, 2) $padding-xs * 6 math.div($padding-xs, 2) $padding-xs;
$filter-dropdown-max-height: 190px;

    /** Menu **/
$left-menu-container-width: 270px;
// $left-menu-item-padding: $padding-md;
// $top-menu-item-padding: $padding-md;

$menu-border-width: 4px;
$menu-item-active-text-color: $color-accent;

    /** Layout **/
$shell-header-height: 59px;
$shell-footer-height: 43px;
$shell-padding-vertical: $padding-xl;
$shell-height: $shell-header-height + $shell-footer-height + ($shell-padding-vertical * 2);
$shell-content-height: calc(100vh - #{$shell-height});
$shell-scroll-width: 20px;

$sizes: (
      ) !default;
$sizes: map-merge((25: 25%, 50: 50%, 75: 75%, 100: 100%, auto: auto),
        $sizes
    );