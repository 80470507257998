@use '../variables';
@use '../mixins/general';

@mixin make-theme-buttons() {

  .button {
    @include general.control();

    display: inline-block;
    text-align: center;
    min-width: calc(100 - (var(--spacer-sm) * 2));
    padding: var(--spacer-sm);
    font-weight: var(--font-weight-medium);
    border: 1px solid var(--button-primary-border-color);
    border-radius: var(--radius-sm);

    @include make-button(var(--button-primary-background-color), var(--button-primary-text-color));

    @each $className, $color in variables.$button-config {
      &.#{$className} {
        @include make-button($color, var(--button-primary-text-color));
      }
    }
  }
}

@mixin make-button($color, $textColor) {
  background-color: $color;
  color: $textColor;
  border-color: $color;

  &.accent {
    background-color: var(--button-accent-background-color);
    color: var(--button-accent-text-color);

    .icon {
      fill: var(--button-accent-icon-color);
    }
  }

  &.danger {
    background-color: var(--button-danger-background-color);
    color: var(--button-danger-text-color);

    .icon {
      fill: var(--button-danger-text-color);
    }
  }

  &.outline {
    background-color: var(--button-secondary-background-color);
    color: $color;
    border-color: var(--button-secondary-border-color);

    .icon {
      fill: var(--button-secondary-icon-color);
    }

    &.disabled,
    &[disabled] {
      background-color: var(--button-secondary-background-color--disabled);
      color: var(--button-secondary-text-color--disabled);
      border-color: var(--button-secondary-border-color--disabled);

      &.icon {
        fill: var(--button-secondary-icon-color--disabled);
      }
    }
  }

  &:active {
    border-color: $color;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--button-primary-background-color--active);
    color: var(--button-primary-text-color--active);
    border-color: var(--button-primary-border-color--active);

    .icon {
      fill: var(--button-primary-icon-color--active);
    }

    &.accent {
      background-color: var(--button-accent-background-color--active);
      color: var(--button-accent-text-color--active);
      border-color: var(--button-accent-border-color--active);

      .icon {
        fill: var(--button-accent-icon-color--active);
      }
    }

    &.outline {
      background-color: var(--button-secondary-background-color--active);
      color: var(--button-secondary-text-color--active);
      border-color: var(--button-secondary-border-color--active);

      .icon {
        fill: var(--button-secondary-icon-color--active);
      }
    }

    &.danger {
      background-color: var(--button-danger-background-color--active);
      color: var(--button-danger-text-color--active);
      border-color: var(--button-danger-border-color--active);

      .icon {
        fill: var(--button-danger-icon-color--active);
      }
    }
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    color: var(--button-primary-text-color--disabled);
    border-color: var(--button-primary-border-color--disabled);
    background-color: var(--button-primary-background-color--disabled);

    &.icon {
      fill: var(--button-primary-icon-color--disabled);
    }
  }

  &.btn-xs {
    padding: var(--spacer-xs);
  }

  &.btn-sm {
    padding: var(--spacer-sm);
  }

  &.btn-md {
    padding: var(--spacer-md);
  }
}
