@use '../variables';
@use 'sass:math';

$chart-legend-width: 22px;
$chart-legend-height: 7px;

@mixin make-card-tree() {
  .card-tree {
    display: flex;
    flex-direction: column;

    .icon {
      transform: translateX(var(--spacer-sm));
    }

    .card-parent-container {
      position: relative;

      .card {
        max-width: 300px;
        margin-bottom: 100px;
        margin-left: calc(var(--spacer-lg) / 2);
        margin-right: calc(var(--spacer-lg) / 2);
        position: relative;

        .card-title-text {
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &.card-title-editable {
            max-width: 80%;
          }
        }
      }

      .card-border {
        height: 50px;
        width: 2px;
        background-color: var(--background-neutral-contrast);
        position: absolute;
        display: block;
        left: 50%;
        margin: 0;

        &.card-border-top {
          top: -1px;
          transform: translateY(-100%);
        }

        &.card-border-bottom {
          bottom: -1px;
          transform: translateY(100%);
        }

        &.card-border-horizontal {
          width: 100%;
          height: 2px;
          top: -50px;
          left: 0;
          transform: translateY(-100%);
        }

        &.card-border-horizontal-last {
          width: calc(50% + 2px);
          left: 0;
        }

        &.card-border-horizontal-first {
          left: 50%;
          width: 50%;
        }
      }
    }

    .legend-item {
      width: $chart-legend-width;
      height: $chart-legend-height;
    }
  }
}
