/* stylelint-disable */

@use '../variables';
@use 'general';
@use 'sass:map';

$input-height: 45px;

@mixin make-disabled($inner: '&',
  $outer: '&',
  $background-color: #{var(--background-neutral-overlay)},
  $border-color: var(--border-neutral-fill),
  $icon-color: var(--text-neutral-fill),
  $text-color: var(--text-neutral-fill)) {

  #{$inner}.disabled,
  #{$inner}:disabled,
  #{$inner}[readonly] {
    #{$outer} {
      color: $text-color;
      border-color: $border-color;
      background-color: $background-color;
      cursor: not-allowed;
      pointer-events: none;

      &:hover,
      &:active {
        fill: $icon-color;
        color: $text-color;
        border-color: $border-color;
        background-color: $background-color;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  &.outlined {

    #{$inner}.disabled,
    #{$inner}:disabled,
    #{$inner}[readonly] {
      #{$outer} {
        background-color: transparent;

        &:hover,
        &:active {
          color: $border-color;
          background-color: transparent;
        }

        &.selected {
          border-color: var(--border-brand);
          color: var(--text-secondary);
        }
      }
    }
  }
}

@mixin input-control() {
  @include general.control();
  @include general.overrides($no-border: true, $no-background: true);

  border: 1px solid var(--border-neutral-fill);
  height: $input-height;
}

@mixin make-input-sizes() {
  @each $size-type, $config in variables.$form-input-size-config {
    $height: map.get($config, 'height');
    $padding: map.get($config, 'padding');

    &.input-#{$size-type} {
      padding: $padding;
      height: $height;
    }
  }
}

@mixin make-focus-state() {

  &.input-focusing,
  &:focus,
  &:active {
    border-color: var(--border-brand-fill);
  }
}

@mixin make-hover-state() {
  &:hover {
    border-color: var(--border-brand-fill);
  }
}

@mixin make-danger-state() {
  &.input-danger {
    border-color: var(--border-danger);
  }
}