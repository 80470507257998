@mixin noselect() {
  user-select: none;
  outline: 0;
}

@mixin ellipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin action() {
  cursor: pointer;
  color: var(--button-primary-background-color);
}

@mixin option-hover() {
  &:hover {
    @include action();

    background-color: var(--background-primary);
  }
}

@mixin bring-forward($height) {
  $size: $height * 2 + px;
  $size2: $height * 3 + px;

  box-shadow: 0 $size $size2 2px rgba(0, 0, 0, 0.03);
}

@mixin bring-forward-card($height) {
  @include bring-forward($height);

  border: var(--card-card-border-color);
  background-color: var(--background-primary);
  z-index: calc(var(--default-z-index) + $height);
}

@mixin control {
  @include noselect();

  outline: none;
  display: block;
  position: relative;
}

@mixin zero-spacing {
  padding: 0;
  margin: 0;
}

@mixin build-arrow($size, $rotation) {
  content: ' ';
  display: inline-block;
  width: $size;
  height: $size;
  border: 1px solid var(card-border-color);
  border-top-width: 0;
  border-left-width: 0;
  transform: rotate($rotation);
}

@mixin overrides($no-border: false, $no-background: false) {
  @if $no-border==true {
    .no-border {
      border: 0 none;
    }
  }

  @if $no-background==true {
    .no-background {
      background-color: transparent;
    }
  }
}