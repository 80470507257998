@use '../variables';
@use 'sass:map';

@mixin make-form-control() {
  @each $size-type, $width in variables.$form-control-config {
    .form-control-#{$size-type} {
      max-width: $width;
      width: $width;
    }
  }
}
