@use '../mixins/general';

@mixin make-theme-tooltip() {
  .tooltip {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--text-secondary);
    background-color: var(--background-primary);
    box-shadow: var(--shadow-sm);
    padding: v(--spacer-xs) var(--spacer-sm);
    font-weight: var(--font-weight-medium);
    border-radius: var(--radius-sm);
    pointer-events: none;

    &.primary {
      background-color: var(--background-neutral-contrast);
    }

    &.accent {
      background-color: var(--background-brand);
    }

    &.success {
      background-color: var(--background-success);
    }

    &.danger {
      background-color: var(--background-danger);
    }
  }
}
