/* stylelint-disable */

@use '../variables';
@use '../mixins/animations';

$color-loader: var(--background-neutral-contrast);

@mixin make-theme-loading() {
  @include make-line-loading();
  @include make-skeleton-loading();
}

@mixin make-line-loading() {
  @keyframes loading-line-animate {
    0% {
      left: 0;
    }

    100% {
      left: calc(100% - (100% / 5));
    }
  }

  .loading-line {
    background-color: $color-loader;
    border-radius: 100%;
    height: 3px;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 20%;
      height: 3px;
      background-color: var(--background-neutral-contrast);
      animation: loading-line-animate 1.5s;
      animation-direction: alternate-reverse;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
}

@mixin make-skeleton-loading {
  .loading-box {
    display: block;
    position: relative;
    height: 100%;
    flex-grow: 1;

    .loading-box-content {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--background-primary);
      border: 1px solid var(--border-neutral);
      border-radius: var(--radius-sm);
    }

    .loading-card-skeleton {
      header {
        @include animations.animate-opacity();

        width: 100%;
        height: 25px;
        background: $color-loader;
        border: 1px solid var(--border-neutral);
        border-top-left-radius: var(--radius-sm);
        border-top-right-radius: var(--radius-sm);
      }

      article {
        @include animations.animate-opacity();

        flex: 1;
        width: 100%;
        margin: var(--spacer-md) 0;
        padding: 0 var(--spacer-md);
        overflow-y: hidden;

        .items {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            display: block;
            height: 15px;
            margin-bottom: var(--spacer-md);
            background: $color-loader;

            &:nth-child(1) {
              width: 95%;
            }

            &:nth-child(2) {
              width: 55%;
            }

            &:nth-child(3) {
              width: 70%;
            }

            &:nth-child(4) {
              width: 25%;
            }

            &:nth-child(5) {
              width: 40%;
            }

            &:nth-child(6) {
              width: 65%;
            }

            &:nth-child(7) {
              width: 50%;
            }

            &:nth-child(8) {
              width: 70%;
            }
          }
        }
      }

      footer {
        @include animations.animate-opacity();

        width: 100%;
        height: 25px;
        background: $color-loader;
        border: 1px solid var(--border-neutral);
        border-bottom-left-radius: var(--radius-sm);
        border-bottom-right-radius: var(--radius-sm);
      }
    }

    .loading-chart-skeleton {
      header {
        @include animations.animate-opacity();

        width: 100%;
        height: 25px;
        margin-top: var(--spacer-sm);
        border-bottom: 1px solid $color-loader;
        background-image: linear-gradient($color-loader, $color-loader);
        background-size: 20% 15px;
        background-position: top left var(--spacer-md);
        background-repeat: no-repeat;
      }

      article {
        @include animations.animate-opacity();

        display: flex;
        flex: 1;
        margin: var(--spacer-md) 0;
        padding: 0 var(--spacer-md);

        .y-axis-title {
          float: left;
          width: 30px;
          height: 100%;
          background-image: linear-gradient($color-loader, $color-loader);
          background-size: 15px 30%;
          background-position: left center;
          background-repeat: no-repeat;
        }

        .chart {
          float: left;
          display: table;
          table-layout: fixed;
          width: calc(100% - 30px);
          height: calc(100% - 30px);
          background-image: linear-gradient(to top, $color-loader 2%, transparent 2%),
						linear-gradient(to top, $color-loader 0%, $color-loader 10px, transparent 10px);
          background-size: calc(100% - 25px) 50px, 10px 30px;
          background-position: left 20px bottom, left bottom;
          background-repeat: repeat-y, repeat-y;
          margin-top: 30px;

          li {
            position: relative;
            display: table-cell;
            vertical-align: bottom;
            height: 100%;

            span {
              margin: 0 35%;
              display: block;
              background: $color-loader;

              &:nth-child(2) {
                @extend .text-break;

                margin: 15px 25% 0;
                height: 15px;
              }
            }

            &:nth-child(1) span {
              &:nth-child(1) {
                height: 60%;
              }
            }

            &:nth-child(2) span {
              &:nth-child(1) {
                height: 90%;
              }
            }

            &:nth-child(3) span {
              &:nth-child(1) {
                height: 40%;
              }
            }

            &:nth-child(4) span {
              &:nth-child(1) {
                height: 10%;
              }
            }

            &:nth-child(5) span {
              &:nth-child(1) {
                height: 75%;
              }
            }
          }
        }
      }

      footer {
        @include animations.animate-opacity();

        width: 100%;
        height: 25px;
        margin-bottom: var(--spacer-xs);
        background-image: linear-gradient($color-loader, $color-loader);
        background-size: 20% 15px;
        background-position: bottom var(--spacer-sm) center;
        background-repeat: no-repeat;
      }
    }

    .loading-overview-skeleton {
      header {
        @include animations.animate-opacity();

        width: 100%;
        height: 25px;
        margin-top: var(--spacer-sm);
        border-bottom: 1px solid $color-loader;
        background-image: linear-gradient($color-loader, $color-loader);
        background-size: 20% 15px;
        background-position: top left var(--spacer-md);
        background-repeat: no-repeat;
      }

      article {
        @include animations.animate-opacity();

        flex: 1;
        margin: var(--spacer-md) 0;
        padding: 0 var(--spacer-md);
        overflow-y: hidden;

        .overview-skeleton-content {
          display: table;
          table-layout: fixed;
          margin: var(--spacer-md) 0 var(--spacer-xl) 0;
          padding: 0;
          width: 100%;
          height: 35px;

          li {
            position: relative;
            display: table-cell;
            vertical-align: top;

            span {
              display: block;
              height: 10px;
              background: $color-loader;

              &:nth-child(2) {
                margin-top: 10px;
              }
            }

            &:nth-child(1) span {
              width: 30%;

              &:nth-child(2) {
                width: 75%;
              }
            }

            &:nth-child(2) span {
              width: 30%;
              margin-left: 25%;

              &:nth-child(2) {
                width: 45%;
                margin-left: 25%;
              }
            }

            &:nth-child(3) span {
              width: 30%;
              margin-left: 25%;

              &:nth-child(2) {
                width: 25%;
                margin-left: 25%;
              }
            }
          }
        }
      }
    }

    .loading-table-skeleton {
      header {
        @include animations.animate-opacity();

        width: 100%;
        height: 25px;
        margin-top: var(--spacer-sm);
        border-bottom: 1px solid $color-loader;
        background-image: linear-gradient($color-loader, $color-loader);
        background-size: 20% 15px;
        background-position: top left var(--spacer-md);
        background-repeat: no-repeat;
      }

      article {
        @include animations.animate-opacity();

        flex: 1;
        margin: 0 0 var(--spacer-sm) 0;
        padding: 0;
        overflow-y: hidden;

        .table-skeleton-content {
          display: table;
          table-layout: fixed;
          margin: 0 0 var(--spacer-lg) 0;
          padding: 0;
          width: 100%;
          height: 30px;

          li {
            position: relative;
            display: table-cell;
            vertical-align: top;
            padding-left: var(--spacer-md);
            border-bottom: 1px solid $color-loader;

            span {
              display: block;
              height: 10px;
              background: $color-loader;
            }

            &:nth-child(1) span {
              width: 25%;
            }

            &:nth-child(2) span {
              width: 15%;
              margin-left: 15%;
            }

            &:nth-child(3) {
              span {
                float: left;
              }

              span:nth-child(1) {
                width: 10%;
                margin-left: 15%;
              }

              span:nth-child(2) {
                width: 15%;
                margin-left: var(--spacer-xs);
              }

              span:nth-child(3) {
                width: 10%;
                margin-left: var(--spacer-xs);
              }
            }

            &:nth-child(4) span {
              width: 25%;
              margin-left: 20%;
            }
          }

          &.header-row {
            margin-top: var(--spacer-lg);

            li {
              span {
                height: 15px;
                background: $color-loader;
              }

              &:nth-child(1) span {
                width: 30%;
              }

              &:nth-child(2) span {
                width: 35%;
                margin-left: 15%;
              }

              &:nth-child(3) span {
                width: 25%;
                margin-left: 15%;
              }

              &:nth-child(4) span {
                width: 30%;
                margin-left: 15%;
              }
            }
          }

          &.odd {
            li {
              &:nth-child(1) span {
                width: 35%;
              }
            }
          }
        }
      }
    }
  }
}
