@mixin badge($bg-color, $text-color) {
  display: inline-block;
  height: fit-content;
  background-color: $bg-color;
  text-align: center;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-sm);
  color: $text-color;
  border: 1px solid $bg-color;
  border-radius: var(--radius-sm);
  padding: var(--spacer-xs) var(--spacer-sm);
  text-transform: uppercase;
  min-width: 48px;
}

@mixin create-badge($class, $bg-color, $text-color) {
  .#{$class} {
    @include badge($bg-color, $text-color);
  }
}

@mixin create-badge-sm($class, $bg-color, $text-color) {
  .#{$class} {
    @include badge($bg-color, $text-color);

    min-width: auto;
    border-radius: var(--radius-xs);
    padding: calc(var(--spacer-xs) / 2) calc(var(--spacer-xs) / 2);
  }
}

@mixin create-badge-sm-b($class, $bg-color, $text-color) {
  .#{$class} {
    @include badge($bg-color, $text-color);

    min-width: auto;
    background-color: transparent;
    border-radius: var(--radius-xs);
    border-width: 1.4px;
    padding: calc(var(--spacer-xs) / 2) calc(var(--spacer-xs) / 2);
  }
}

@mixin create-pill($class, $bg-color, $text-color) {
  .#{$class} {
    @include badge($bg-color, $text-color);

    padding: var(--spacer-xs) var(--spacer-sm);
    border-radius: var(--radius-xl);
  }
}

@mixin setup() {
  @include create-badge('badge-primary', var(--background-brand), var(--text-primary));
  @include create-badge('badge-primary-light', var(--background-brand-overlay), var(--text-primary));
  @include create-badge('badge-primary-dark', var(--background-brand-contrast), var(--text-brand));
  @include create-badge('badge-secondary', var(--background-neutral-contrast), var(--text-primary));
  @include create-badge('badge-secondary-light', var(--background-neutral-overlay), var(--text-neutral-contrast));
  @include create-badge('badge-secondary-dark', var(--background-neutral-contrast), var(--text-primary));
  @include create-badge('badge-success', var(--background-success), var(--text-secondary));
  @include create-badge('badge-success-light', var(--background-success-overlay), var(--text-success-contrast));
  @include create-badge('badge-success-dark', var(--background-success-contrast), var(--text-primary));
  @include create-badge('badge-danger', var(--background-danger), var(--text-primary));
  @include create-badge('badge-danger-light', var(--background-danger-overlay), var(--text-danger-contrast));
  @include create-badge('badge-danger-dark', var(--background-danger-contrast), var(--text-primary));
  @include create-badge('badge-warning', var(--background-warning), var(--text-secondary));
  @include create-badge('badge-warning-light', var(--background-warning-overlay), var(--text-warning-contrast));
  @include create-badge('badge-warning-dark', var(--background-warning-contrast), var(--text-secondary));
  @include create-badge('badge-info', var(--background-info), var(--text-primary));
  @include create-badge('badge-royal', var(--background-royal), var(--text-primary));
  @include create-badge('badge-clear', var(--background-clear), var(--text-secondary));
  @include create-pill('pill-primary', var(--background-brand), var(--text-primary));
  @include create-pill('pill-secondary', var(--background-neutral-contrast), var(--text-primary));
  @include create-pill('pill-success', var(--background-success), var(--text-secondary));
  @include create-pill('pill-danger', var(--background-danger), var(--text-primary));
  @include create-pill('pill-warning', var(--background-warning), var(--text-secondary));
  @include create-pill('pill-info', var(--background-info), var(--text-primary));
  @include create-pill('pill-royal', var(--background-royal), var(--text-primary));
  @include create-pill('pill-clear', var(--background-clear), var(--text-secondary));
  @include create-badge-sm('badge-primary-sm', var(--background-brand-overlay), var(--text-brand));
  @include create-badge-sm('badge-secondary-sm', var(--background-neutral-overlay), var(--text-neutral-contrast));
  @include create-badge-sm('badge-success-sm', var(--background-success-overlay), var(--text-success));
  @include create-badge-sm('badge-danger-sm', var(--background-danger-overlay), var(--text-danger));
  @include create-badge-sm('badge-warning-sm', var(--background-warning-overlay), var(--text-warning));
  @include create-badge-sm('badge-info-sm', var(--background-info-overlay), var(--text-info));
  @include create-badge-sm('badge-royal-sm', var(--background-royal-overlay), var(--text-royal));
  @include create-badge-sm('badge-clear-sm', var(--background-clear-overlay), var(--text-clear));
  @include create-badge-sm-b('badge-primary-sm-b', var(--background-brand), var(--background-brand));
  @include create-badge-sm-b('badge-secondary-sm-b', var(--background-neutral-contrast), var(--background-neutral-contrast));
  @include create-badge-sm-b('badge-success-sm-b', var(--background-success), var(--background-success));
  @include create-badge-sm-b('badge-danger-sm-b', var(--background-danger), var(--background-danger));
  @include create-badge-sm-b('badge-warning-sm-b', var(--background-warning), var(--background-warning));
  @include create-badge-sm-b('badge-info-sm-b', var(--background-info), var(--background-info));
  @include create-badge-sm-b('badge-royal-sm-b', var(--background-royal), var(--background-royal));
  @include create-badge-sm-b('badge-clear-sm-b', var(--background-clear), var(--background-clear));
}
